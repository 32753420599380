export default [
  {
    name: "spaceRed",
    type: "cubeTexture",
    path: [
      "textures/skybox/SpaceRed/right.png",
      "textures/skybox/SpaceRed/left.png",
      "textures/skybox/SpaceRed/top.png",
      "textures/skybox/SpaceRed/bottom.png",
      "textures/skybox/SpaceRed/front.png",
      "textures/skybox/SpaceRed/back.png",
    ],
  },

  {
    name: "robot",
    type: "gltfModel",
    path: "models/robot_playground/scene.gltf",
  },

  {
    name: "mario",
    type: "gltfModel",
    path: "models/Mario/scene.gltf",
  },
  {
    name: "SpaceInvaders",
    type: "gltfModel",
    path: "models/SpaceInvaders/spaceInvader.gltf",
  },
  {
    name: "pokemon",
    type: "gltfModel",
    path: "models/pokemon/scene.gltf",
  },
  {
    name: "cosmonaut",
    type: "gltfModel",
    path: "models/cosmonaut/scene.gltf",
  },
  {
    name: "jetSetSonic",
    type: "gltfModel",
    path: "models/jet_set_sonic/scene.gltf",
  },
  {
    name: "chicken",
    type: "gltfModel",
    path: "models/CrossyRoads/chicken.glb",
  },
];
