import * as THREE from "three";
import Experience from "../Experience";

export default class Audio {
  constructor(audioName) {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    //audio loaders
    this.listener = new THREE.AudioListener();
    this.sound = new THREE.Audio(this.listener);
    this.audioLoader = new THREE.AudioLoader();
    this.toggle = true;

    //grab audio button
    this.audioBtn = document.getElementById("audio");

    //Audio loader
    this.audioPath = "Audio/";
    this.audioLoader.load(`${this.audioPath}${audioName}`, (buffer) => {
      this.sound.setBuffer(buffer);
      this.sound.setLoop(true);
      this.sound.setVolume(0.5);
      this.sound.hasPlaybackControl = true;
    });

    this.toggleSound();
  }

  toggleSound() {
    this.audioBtn.onclick = () => {
      if (this.toggle) {
        this.sound.play();
      } else {
        this.sound.pause();
      }
      this.toggle = !this.toggle;
    };
  }
}
