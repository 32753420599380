import * as THREE from "three";
import { Vector3 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import Experience from "./Experience";

export default class Camera {
  constructor() {
    console.log("Camera Class Initialized");

    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;

    this.setInstance();
    this.setOrbitControls();
  }

  setInstance() {
    this.instance = new THREE.PerspectiveCamera(
      35,
      this.sizes.width / this.sizes.height,
      0.1,
      10000
    );

    this.instance.position.set(2, 2, 5);
    this.instance.rotation.set(0, 0, 0, "XYZ");
    this.scene.add(this.instance);
    //const axesHelper = new THREE.AxesHelper(5);
    //this.scene.add(axesHelper);
  }

  getPosition() {
    return this.instance.position;
  }

  getRotation() {
    return this.instance.rotation;
  }

  getInstance() {
    return this.instance;
  }

  setOrbitControls() {
    this.controls = new OrbitControls(this.instance, this.canvas);
    this.controls.enableDamping = true;
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix();
  }

  update() {
    this.controls.update();
  }
}
