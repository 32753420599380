import * as THREE from "three";
import Experience from "../Experience";

export default class Planet {
  constructor(
    name,
    position = new THREE.Vector3(0, 0, 0),
    scale = new THREE.Vector3(1, 1, 1),
    rotation = new THREE.Euler(0, 0, 0, "XYZ")
  ) {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.name = name;

    //position, scale, rotation
    this.position = position;
    this.scale = scale;
    this.rotation = rotation;

    this.createPlanet();
  }

  createPlanet() {
    const Path = `models/planets/${this.name}`;
    const diffuseTexture = new THREE.TextureLoader().load(
      `${Path}/diffuse.png`
    );
    const normalTexture = new THREE.TextureLoader().load(`${Path}/normal.png`);
    const emisiveTexture = new THREE.TextureLoader().load(
      `${Path}/emissive.png`
    );
    const specularTexture = new THREE.TextureLoader().load(
      `${Path}/specular.png`
    );
    const bumpTexture = new THREE.TextureLoader().load(`${Path}/bump.png`);

    this.planet = new THREE.Mesh(
      new THREE.SphereGeometry(30, 64, 64),
      new THREE.MeshPhongMaterial({
        map: diffuseTexture,
        normalMap: normalTexture,
        emissive: 0xffffff,
        emissiveMap: emisiveTexture,
        emissiveIntensity: 1,
        specular: 0x111111,
        specularMap: specularTexture,
      })
    );

    if (bumpTexture != null) {
      var geometry = new THREE.SphereGeometry(30, 64, 64);
      var material = new THREE.MeshPhongMaterial({
        map: bumpTexture,
        side: THREE.DoubleSide,
        opacity: 0.5,
        transparent: true,
        depthWrite: false,
      });
      var cloudMesh = new THREE.Mesh(geometry, material);
      this.planet.add(cloudMesh);
    }

    this.planet.position.copy(this.position);
    this.planet.scale.copy(this.scale);
    this.planet.rotation.copy(this.rotation);

    this.scene.add(this.planet);
    this.resources.items[this.name] = this.planet;
  }

  getInstance() {
    return this.planet;
  }

  getWorldPosition(obj) {
    this.planet.getWorldPosition(obj);
  }

  setPositionWithMatrix(matrix) {
    this.planet.position.applyMatrix4(matrix);
  }

  update(rotateSpeed, rotateAxis) {
    this.planet[rotateAxis](THREE.Math.degToRad(rotateSpeed * this.time.delta));
  }
}
