import * as THREE from "three";
import Experience from "../Experience";

export default class StaticMesh {
  constructor(
    modelName,
    position,
    scale = new THREE.Vector3(1, 1, 1),
    rotation = new THREE.Euler(0, 0, 0, "XYZ")
  ) {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;

    //position, scale, rotation
    this.position = position;
    this.scale = scale;
    this.rotation = rotation;

    this.resource = this.resources.items[modelName];
    this.setModel();
  }

  getWorldPosition(objPosition) {
    this.model.getWorldPosition(objPosition);
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.scale.set(this.scale.x, this.scale.y, this.scale.z);
    this.model.position.set(this.position.x, this.position.y, this.position.z);
    this.model.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z);
    this.scene.add(this.model);

    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
      }
    });
  }

  rotateObject(rotateSpeed, rotateAxis) {
    this.model[rotateAxis](THREE.Math.degToRad(rotateSpeed * this.time.delta));
  }

  setPositionWithMatrix(matrix) {
    this.model.position.applyMatrix4(matrix);
  }
}
