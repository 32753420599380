import * as THREE from "three";
import Experience from "../Experience";

export default class AnimatedMesh {
  constructor(
    modelName,
    positionVector = new THREE.Vector3(0, 0, 0),
    scaleVector = new THREE.Vector3(0, 0, 0),
    rotationVector = new THREE.Euler(0, 0, 0, "XYZ")
  ) {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;
    this.modelName = modelName;
    this.animationNames = [];
    this.positionVector = positionVector;
    this.scaleVector = scaleVector;
    this.rotationVector = rotationVector;

    //Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder(modelName);
    }

    //Setup
    this.resource = this.resources.items[modelName];

    this.setModel();
    this.setAnimation();
  }

  getWorldPosition(objPosition) {
    this.model.getWorldPosition(objPosition);
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.position.copy(this.positionVector);
    this.model.scale.copy(this.scaleVector);
    this.model.rotation.copy(this.rotationVector);
    this.scene.add(this.model);

    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
      }
    });
  }

  setAnimation() {
    this.animation = {};
    this.animation.mixer = new THREE.AnimationMixer(this.model);
    this.animation.actions = {};

    //Add all animations to animation mixer
    for (const currentAnimation of this.resource.animations) {
      //keep track of animations name to make life easier when storing animations in debug menu
      this.animationNames.push(currentAnimation.name);
      this.animation.actions[currentAnimation.name] =
        this.animation.mixer.clipAction(currentAnimation);
    }

    //set first animation as default and play
    this.animation.actions.current =
      this.animation.actions[Object.keys(this.animation.actions)[0]];
    this.animation.actions.current.play();

    //change animations
    this.animation.play = (name) => {
      const newAction = this.animation.actions[name];
      const oldAction = this.animation.actions.current;
      newAction.reset();
      newAction.play();
      newAction.crossFadeFrom(oldAction, 1);
      this.animation.actions.current = newAction;
    };

    //register all animations in debug menu
    if (this.debug.active) {
      const debugObject = {};
      for (const name of this.animationNames) {
        const animationFunction = () => {
          this.animation.play(name);
        };
        debugObject["play" + name] = animationFunction;
        this.debugFolder.add(debugObject, "play" + name);
      }
    }
  }

  rotateObject(rotateSpeed, rotateAxis) {
    this.model[rotateAxis](THREE.Math.degToRad(rotateSpeed * this.time.delta));
  }

  setPositionWithMatrix(matrix) {
    this.model.position.applyMatrix4(matrix);
  }

  update() {
    this.animation.mixer.update(this.time.delta * 0.001);
  }
}
