import * as THREE from "three";
import Experience from "../Experience";
import Environment from "./Environment";
import Audio from "../Utils/Audio";
import Floor from "./Floor";
import AnimatedMesh from "./AnimatedMesh";
import StaticMesh from "./StaticMesh";
import Planet from "./Planet";
import Galaxy from "./Galaxy";
import WebPageHandler from "./WebPageHandler";
import EventEmitter from "../Utils/EventEmitter";
import { Vector3 } from "three";
import { degToRad } from "three/src/math/mathutils";

export default class World extends EventEmitter {
  constructor() {
    super();
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera;
    this.webPageHandler = new WebPageHandler();
    this.galaxyObjects = {};
    this.time = this.experience.time;
    this.assets_loaded = false;

    //Wait for resources
    this.resources.on("ready", () => {
      //Setup

      this.galaxyObjects["mario"] = {
        type: "staticMesh",
        obj: (this.mario = new StaticMesh(
          "mario",
          new THREE.Vector3(-7, 0, 0),
          new THREE.Vector3(0.01, 0.01, 0.01)
        )),
        objOffset: new THREE.Vector3(-0.5, 0.1, 0.23),
        lookOffset: new THREE.Vector3(0, 0, 0.2),
      };

      this.galaxyObjects["jetSetSonic"] = {
        type: "staticMesh",
        obj: (this.jetSetSonic = new StaticMesh(
          "jetSetSonic",
          new THREE.Vector3(-2, 2, 1),
          new THREE.Vector3(0.05, 0.05, 0.05),
          new THREE.Euler(0, 0, degToRad(-20), "XYZ")
        )),
        objOffset: new THREE.Vector3(-0.5, 0.8, 0.15),
        lookOffset: new THREE.Vector3(0, 0, 0.3),
      };
      this.galaxyObjects["chicken"] = {
        type: "staticMesh",
        obj: (this.chicken = new StaticMesh(
          "chicken",
          new THREE.Vector3(4, -1, 1),
          new THREE.Vector3(0.1, 0.1, 0.1),
          new THREE.Euler(0, 0, 0, "XYZ")
        )),
        objOffset: new THREE.Vector3(0, 0, -0.5),
        lookOffset: new THREE.Vector3(-0.15, 0, 0),
      };

      this.galaxyObjects["pokemon"] = {
        type: "staticMesh",
        obj: (this.pokemon = new AnimatedMesh(
          "pokemon",
          new THREE.Vector3(-5, 0, 5),
          new THREE.Vector3(0.0005, 0.0005, 0.0005),
          new THREE.Euler(0, 0, degToRad(-20), "XYZ")
        )),
        objOffset: new THREE.Vector3(0.6, 0.05, 0.23),
        lookOffset: new THREE.Vector3(8, -0.05, 0.1),
      };

      //const axesHelper = new THREE.AxesHelper(5);
      //this.scene.add(axesHelper);

      this.galaxyObjects["spaceInvader"] = {
        type: "animatedMesh",
        obj: (this.spaceInvader = new AnimatedMesh(
          "SpaceInvaders",
          new THREE.Vector3(5, 1, 0),
          new THREE.Vector3(0.0015, 0.0015, 0.0015)
        )),
        objOffset: new THREE.Vector3(0.09, 0.03, 0.23),
        lookOffset: new THREE.Vector3(0.09, -0.03, 0),
      };

      this.galaxyObjects["cosmonaut"] = {
        type: "animatedMesh",
        obj: (this.cosmonaut = new AnimatedMesh(
          "cosmonaut",
          new THREE.Vector3(3, -1, 2),
          new THREE.Vector3(0.0003, 0.0003, 0.0003)
        )),
        objOffset: new THREE.Vector3(0.09, 0.03, 0.23),
        lookOffset: new THREE.Vector3(0.09, -0.03, 0),
      };

      this.galaxyObjects["robot"] = {
        type: "animatedMesh",
        obj: (this.robot = new AnimatedMesh(
          "robot",
          new THREE.Vector3(5, 1, 5),
          new THREE.Vector3(0.003, 0.003, 0.003)
        )),
        objOffset: new THREE.Vector3(0.09, 0.05, 0.23),
        lookOffset: new THREE.Vector3(0.09, -0.06, 0),
      };

      this.galaxyObjects["earth"] = {
        type: "planet",
        obj: (this.earth = new Planet(
          "earthplanet",
          new THREE.Vector3(5, 1, 2),
          new THREE.Vector3(0.001, 0.001, 0.001)
        )),
        objOffset: new THREE.Vector3(0, 0, 0.26),
        lookOffset: new THREE.Vector3(0.08, 0, 0.05),
      };

      this.galaxyObjects["gasPlanet"] = {
        type: "planet",
        obj: (this.gasPlanet = new Planet(
          "gasplanet",
          new THREE.Vector3(-2.5, -1, 0),
          new THREE.Vector3(0.001, 0.001, 0.001)
        )),
        objOffset: new THREE.Vector3(0, 0, 0.26),
        lookOffset: new THREE.Vector3(0.08, 0, 0.05),
      };

      this.galaxyObjects["gasPlanet2"] = {
        type: "planet",
        obj: (this.gasPlanet2 = new Planet(
          "gasplanet2",
          new THREE.Vector3(1, -1, -2),
          new THREE.Vector3(0.001, 0.001, 0.001)
        )),
        objOffset: new THREE.Vector3(0, 0, 0.26),
        lookOffset: new THREE.Vector3(0.08, 0, 0.05),
      };

      this.galaxyObjects["purplePlanet"] = {
        type: "planet",
        obj: (this.purplePlanet = new Planet(
          "purpleplanet",
          new THREE.Vector3(-4, 1, 2),
          new THREE.Vector3(0.001, 0.001, 0.001)
        )),
        objOffset: new THREE.Vector3(0, 0, 0.26),
        lookOffset: new THREE.Vector3(0.08, 0, 0.05),
      };

      this.galaxyObjects["rockyLavaPlanet"] = {
        type: "planet",
        obj: (this.rockyLavaPlanet = new Planet(
          "rockylavaplanet",
          new THREE.Vector3(0, 0, 5),
          new THREE.Vector3(0.001, 0.001, 0.001)
        )),
        objOffset: new THREE.Vector3(0, 0, 0.26),
        lookOffset: new THREE.Vector3(0.08, 0, 0.05),
      };

      this.galaxyObjects["sun"] = {
        type: "planet",
        obj: (this.sun = new Planet(
          "sun",
          new THREE.Vector3(-5, 1, -2),
          new THREE.Vector3(0.001, 0.001, 0.001)
        )),
        objOffset: new THREE.Vector3(0, 0, 0.26),
        lookOffset: new THREE.Vector3(0.08, 0, 0.05),
      };

      this.galaxy = new Galaxy();

      this.matrix = new THREE.Matrix4();
      this.matrix.makeRotationY(-Math.PI / 2000);

      this.currentObject = undefined;
      this.environment = new Environment("spaceRed");
      this.audio = new Audio("MassEffect.mp3");
      this.assets_loaded = true;
    });

    window.addEventListener("click", () => {
      const objectID = Math.floor(
        Math.random() * Object.keys(this.galaxyObjects).length
      );
      const modelName = document.getElementById("active-model").value;
      this.currentObject = this.galaxyObjects[modelName];
    });
  }

  update() {
    if (this.assets_loaded) {
      //Animate animated meshes
      this.spaceInvader.update();
      this.robot.update();
      this.cosmonaut.update();
      this.pokemon.update();

      //rotate planets in local space
      this.earth.update(-0.01, "rotateY");
      this.gasPlanet.update(0.01, "rotateZ");
      this.gasPlanet2.update(0.04, "rotateY");
      this.purplePlanet.update(0.03, "rotateX");
      this.rockyLavaPlanet.update(-0.05, "rotateZ");
      this.sun.update(-0.001, "rotateY");
      this.mario.rotateObject(0.01, "rotateY");
      this.spaceInvader.rotateObject(0.01, "rotateY");
      this.pokemon.rotateObject(-0.03, "rotateY");
      this.jetSetSonic.rotateObject(-0.03, "rotateY");
      this.chicken.rotateObject(-0.05, "rotateY");

      //Rotate planets around galaxy
      this.earth.setPositionWithMatrix(this.matrix);
      this.gasPlanet.setPositionWithMatrix(this.matrix);
      this.gasPlanet2.setPositionWithMatrix(this.matrix);
      this.purplePlanet.setPositionWithMatrix(this.matrix);
      this.rockyLavaPlanet.setPositionWithMatrix(this.matrix);
      this.sun.setPositionWithMatrix(this.matrix);
      this.galaxy.update();

      //set camera to rotate around selected planet

      if (this.currentObject !== undefined) {
        const objectPosition = new THREE.Vector3();
        const lookOffset = this.currentObject.lookOffset;
        const objOffset = this.currentObject.objOffset;

        this.currentObject.obj.getWorldPosition(objectPosition);
        const cameraOffset = new THREE.Vector3(
          objectPosition.x + objOffset.x,
          objectPosition.y + objOffset.y,
          objectPosition.z + objOffset.z
        );
        this.camera.getInstance().position.lerp(cameraOffset, 0.05);

        this.camera
          .getInstance()
          .lookAt(
            new THREE.Vector3(
              objectPosition.x - lookOffset.x,
              objectPosition.y - lookOffset.y,
              objectPosition.z - lookOffset.z
            )
          );
      } else {
        this.camera.getInstance().position.lerp(new Vector3(2, 2, 5), 0.05);
        this.camera.getInstance().lookAt(-3, -1.5, -2);
      }
    }
  }
}
