import * as THREE from "three";
import Experience from "../Experience";

import galaxyVertexShader from "../shaders/galaxy/vertex.glsl";
import galaxyFragmentShader from "../shaders/galaxy/fragment.glsl";

export default class Galaxy {
  constructor() {
    this.experience = new Experience();
    this.renderer = this.experience.renderer;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    this.clock = new THREE.Clock();

    /**
     * Galaxy
     */
    this.parameters = {};
    this.parameters.count = 300000;
    this.parameters.size = 0.0005;
    this.parameters.radius = 5.5;
    this.parameters.branches = 13;
    this.parameters.spin = 1;
    this.parameters.randomness = 1.361;
    this.parameters.randomnessPower = 9.779;
    this.parameters.insideColor = "#e52706";
    this.parameters.outsideColor = "#082bd9";
    this.parameters.galaxySpeed = 0.107;

    this.geometry = null;
    this.material = null;
    this.points = null;
    this.generateGalaxy();
    //Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("galaxy");
      this.createDebugMenu();
    }
  }

  generateGalaxy = () => {
    if (this.points !== null) {
      this.geometry.dispose();
      this.material.dispose();
      console.log(this.scene);
      this.scene.remove(this.points);
    }

    /**
     * Geometry
     */
    this.geometry = new THREE.BufferGeometry();

    const positions = new Float32Array(this.parameters.count * 3);
    const randomness = new Float32Array(this.parameters.count * 3);
    const colors = new Float32Array(this.parameters.count * 3);
    const scales = new Float32Array(this.parameters.count * 1);

    const insideColor = new THREE.Color(this.parameters.insideColor);
    const outsideColor = new THREE.Color(this.parameters.outsideColor);

    for (let i = 0; i < this.parameters.count; i++) {
      const i3 = i * 3;

      // Position
      const radius = Math.random() * this.parameters.radius;

      const branchAngle =
        ((i % this.parameters.branches) / this.parameters.branches) *
        Math.PI *
        2;

      const randomX =
        Math.pow(Math.random(), this.parameters.randomnessPower) *
        (Math.random() < 0.5 ? 1 : -1) *
        this.parameters.randomness *
        radius;
      const randomY =
        Math.pow(Math.random(), this.parameters.randomnessPower) *
        (Math.random() < 0.5 ? 1 : -1) *
        this.parameters.randomness *
        radius;
      const randomZ =
        Math.pow(Math.random(), this.parameters.randomnessPower) *
        (Math.random() < 0.5 ? 1 : -1) *
        this.parameters.randomness *
        radius;

      positions[i3] = Math.cos(branchAngle) * radius;
      positions[i3 + 1] = 0;
      positions[i3 + 2] = Math.sin(branchAngle) * radius;

      randomness[i3] = randomX;
      randomness[i3 + 1] = randomY;
      randomness[i3 + 2] = randomZ;

      // Color
      const mixedColor = insideColor.clone();
      mixedColor.lerp(outsideColor, radius / this.parameters.radius);

      colors[i3] = mixedColor.r;
      colors[i3 + 1] = mixedColor.g;
      colors[i3 + 2] = mixedColor.b;

      // Scale
      scales[i] = Math.random();
    }

    this.geometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );
    this.geometry.setAttribute(
      "aRandomness",
      new THREE.BufferAttribute(randomness, 3)
    );
    this.geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
    this.geometry.setAttribute("aScale", new THREE.BufferAttribute(scales, 1));

    /**
     * Material
     */
    this.material = new THREE.ShaderMaterial({
      depthWrite: false,
      blending: THREE.AdditiveBlending,
      vertexColors: true,
      uniforms: {
        uTime: { value: 0 },
        uSize: { value: 30 * this.experience.renderer.getPixelRatio() },
      },
      vertexShader: galaxyVertexShader,
      fragmentShader: galaxyFragmentShader,
    });

    /**
     * Points
     */
    this.points = new THREE.Points(this.geometry, this.material);
    this.scene.add(this.points);
  };

  createDebugMenu() {
    this.debugFolder
      .add(this.parameters, "count")
      .min(100)
      .max(1000000)
      .step(100)
      .onFinishChange(this.generateGalaxy);
    this.debugFolder
      .add(this.parameters, "radius")
      .min(0.01)
      .max(20)
      .step(0.01)
      .onFinishChange(this.generateGalaxy);
    this.debugFolder
      .add(this.parameters, "branches")
      .min(2)
      .max(20)
      .step(1)
      .onFinishChange(this.generateGalaxy);
    this.debugFolder
      .add(this.parameters, "randomness")
      .min(0)
      .max(2)
      .step(0.001)
      .onFinishChange(this.generateGalaxy);
    this.debugFolder
      .add(this.parameters, "randomnessPower")
      .min(1)
      .max(10)
      .step(0.001)
      .onFinishChange(this.generateGalaxy);
    this.debugFolder
      .addColor(this.parameters, "insideColor")
      .onFinishChange(this.generateGalaxy);
    this.debugFolder
      .addColor(this.parameters, "outsideColor")
      .onFinishChange(this.generateGalaxy);
    this.debugFolder
      .add(this.parameters, "galaxySpeed")
      .min(0)
      .max(2)
      .step(0.001);
  }

  update() {
    const elapsedTime = this.clock.getElapsedTime();

    this.material.uniforms.uTime.value =
      elapsedTime * this.parameters.galaxySpeed;
  }
}
