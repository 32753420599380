import * as THREE from "three";
import Experience from "../Experience";

import {
  MainPageHTML,
  lifeDexHTML,
  photoGalleryHTML,
  gyroEngineHTML,
  spaceInvadersHTML,
  superMarioBrosHTML,
  crossyRoadsHTML,
  lofiItHTML,
  tuffyBotHTML,
  farmzoidHTML,
  RadioIOHTML,
} from "../WebPages";

export default class WebPageHandler {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    this.sectionDiv = document.getElementById("inner-section");
    this.mainHeaderDiv = document.getElementById("section-main");
    this.mainDiv = document.getElementById("section-main");
    this.buttonDiv = document.getElementById("button-div");

    this.pageCounter = 0;

    this.webPages = {
      RadioIOHTML: {
        model: "jetSetSonic",
        html: RadioIOHTML(),
      },
      lifeDexHTML: {
        model: "pokemon",
        html: lifeDexHTML(),
      },

      photoGalleryHTML: { model: "earth", html: photoGalleryHTML() },
    };
    this.gamePages = {
      gyroEngineHTML: { model: "cosmonaut", html: gyroEngineHTML() },
      spaceInvadersHTML: { model: "spaceInvader", html: spaceInvadersHTML() },
      superMarioBrosHTML: { model: "mario", html: superMarioBrosHTML() },
      crossyRoadsHTML: { model: "chicken", html: crossyRoadsHTML() },
    };
    this.toolPages = {
      lofiItHTML: { model: "gasPlanet2", html: lofiItHTML() },
      tuffyBotHTML: { model: "robot", html: tuffyBotHTML() },
    };
    this.algorithmPages = {
      farmzoidHTML: { model: "rockyLavaPlanet", html: farmzoidHTML() },
    };

    this.currentPages = null;
    this.buttons = {
      "web-tour": document.getElementById("web-tour"),
      "game-tour": document.getElementById("game-tour"),
      "tool-tour": document.getElementById("tool-tour"),
      "algorithm-tour": document.getElementById("algorithm-tour"),
      "prev-page": document.getElementById("prev-page"),
      "main-page": document.getElementById("main-page"),
      "next-page": document.getElementById("next-page"),
    };

    this.setupButtons();
    $("#page-counter").change(() => {
      const pageValue = $("#page-counter").val();
      const prevButton = $("#prev-page");
      
      if (Number(pageValue) === 0) prevButton.addClass("hide");
      else prevButton.removeClass("hide");

      const nextButton = $("#next-page");
      if (Number(pageValue) >= Object.keys(this.currentPages).length - 1)
        nextButton.addClass("hide");
      else nextButton.removeClass("hide");
    });
  }

  setupButtons() {
    this.buttons["next-page"].onclick = () => {
      this.pageCounter = Math.min(
        Object.keys(this.currentPages).length - 1,
        this.pageCounter + 1
      );
      this.switchPage(this.currentPages);
      $("#page-counter").val(this.pageCounter).trigger("change");
    };
    this.buttons["prev-page"].onclick = () => {
      this.pageCounter = Math.max(0, this.pageCounter - 1);
      this.switchPage(this.currentPages);
      $("#page-counter").val(this.pageCounter).trigger("change");
    };
    this.buttons["main-page"].onclick = () => {
      this.sectionDiv.innerHTML = "";
      document.getElementById("active-model").value = null;
      this.buttonDiv.classList.add("hide");
      this.mainDiv.classList.remove("hide");
      this.mainHeaderDiv.classList.remove("hide");
      this.pageCounter = 0;
      $("#page-counter").val(this.pageCounter).trigger("change");
    };
    this.buttons["web-tour"].onclick = () => {
      this.currentPages = this.webPages;
      this.switchPage(this.currentPages);
      this.buttonDiv.classList.remove("hide");
      this.mainDiv.classList.add("hide");
      this.mainHeaderDiv.classList.add("hide");
      this.pageCounter = 0;
      $("#page-counter").val(this.pageCounter).trigger("change");
    };
    this.buttons["game-tour"].onclick = () => {
      this.currentPages = this.gamePages;
      this.switchPage(this.currentPages);
      this.buttonDiv.classList.remove("hide");
      this.mainDiv.classList.add("hide");
      this.mainHeaderDiv.classList.add("hide");
      this.pageCounter = 0;
      $("#page-counter").val(this.pageCounter).trigger("change");
    };
    this.buttons["tool-tour"].onclick = () => {
      this.currentPages = this.toolPages;
      this.switchPage(this.currentPages);
      this.buttonDiv.classList.remove("hide");
      this.mainDiv.classList.add("hide");
      this.mainHeaderDiv.classList.add("hide");
      this.pageCounter = 0;
      $("#page-counter").val(this.pageCounter).trigger("change");
    };
    this.buttons["algorithm-tour"].onclick = () => {
      this.currentPages = this.algorithmPages;
      this.switchPage(this.currentPages);
      this.buttonDiv.classList.remove("hide");
      this.mainDiv.classList.add("hide");
      this.mainHeaderDiv.classList.add("hide");
      this.pageCounter = 0;
      $("#page-counter").val(this.pageCounter).trigger("change");
    };
  }

  switchPage(pageObj) {
    this.sectionDiv.innerHTML = Object.values(pageObj)[this.pageCounter].html;
    document.getElementById("active-model").value =
      Object.values(pageObj)[this.pageCounter].model;
  }
}
