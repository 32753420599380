export function MainPageHTML() {
  return `
    <h2 class="title">Mobile And Web Development</h2>
    <div class="bullet-points-main">
      <p>
          <ul>
            <li>Life Dex</li>
            <li>Photo Gallery</li>
          </ul>
      </p>
      <button id="web-tour" class="myButton">Start Tour</button>
    </div>
    <h2 class="title">Game Development</h2>
    <div class="bullet-points-main"
      <p>
        <ul>
            <li>Gyro Engine</li>
            <li>Space Invaders</li>
            <li>Super Mario Bros</li>
            <li>Crossy Roads</li>
          </ul>
      </p>
      <button id="game-tour" class="myButton">Start Tour</button>
    </div>
    <h2 class="title">Tools/Extensions Development</h2>
    <div class="bullet-points-main">
      <p>
        <ul>
          <li>Lofi It!</li>
          <li>Tuffy Bot</li>
        </ul>
      </p>
      <button id="tool-tour" class="myButton">Start Tour</button>
    </div>
    <h2 class="title">Algorithms</h2>
    <div class="bullet-points-main">
      <p>
        <ul>
            <li>Capstone Project: Farmzoids</li>
          </ul>
      </p>
      <button id="algorithm-tour" class="myButton">Start Tour</button>
    </div>

    
    `;
}

/*NOTE: WEB DEV*/
export function RadioIOHTML() {
  return `
    <h2 class="title">Radio IO</h2>
    <h4>Techology Used</h4>
    <ul>
      <li>Languages: Javascript</li>
      <li>Libraries: React</li>
      <li>API: Teleport, World Bank, Open Weather Map</li>
      <li>Tools: VS Studio Code</li>
    </ul>

    <h4><a href="https://bryancastro.github.io/Radio-IO/">Check Out The Live Demo!</a></h4>

    <br>
    <h4>Description</h4>
    <p>
      Radio IO is a Desktop and Mobile Webapp that allows users to play from a selected list of music. 
      Its Design mimics an old school radio and contains dials that users can use to change stations and the volume.
      It also has a LCD Screen where users can view the current station, volume level, weather, their location and current local time
      using a variety of APIs. They can also choose from a list of themes to give the radio a personal touch 
    </p>
    <br>
    <div class="rowFlex">
      <figure class="imageContainer">
        <img id='radio-io-1' loading="lazy" src="webpages/Radio/radio1.png" onclick="imageClick('radio-io-1', 'imageHoverHorizontal')"></img>
        <figcaption>Main Page</figcaption>
      </figure>
    </div>

    <br>
    <h5>Theme Selection</h5>
    <p>
      The User Can Personalize the radio by selecting from 1 of 5 themes.
      This is simply done by selecting the new theme from the drop down menu on the top right
    </p>
    <br>
    <figure class="imageContainer">
      <img id='radio-io-5' loading="lazy" src="webpages/Radio/radio5.png" onclick="imageClick('radio-io-5', 'imageHoverHorizontal')"></img>
      <figcaption>New Selected Theme</figcaption>
    </figure>
    <br>

    <br>
    <h5>Teleport API</h5>
    <p>
      The Teleport API allows users to search for their current location, simply by typing it in the input box.
      It produces autosuggestions for the cities around the world, the information returned by the Teleport API is 
      then used to retrieve additional information from the World Bank and Open Weather Map APIs. 
    </p>
    <br>
    <figure class="imageContainer">
      <img id='radio-io-2' loading="lazy" src="webpages/Radio/radio2.png" onclick="imageClick('radio-io-2', 'imageHoverHorizontal')"></img>
      <figcaption>Teleport API in Action</figcaption>
    </figure>
    <br>

    <h5>World Bank API</h5>
    <p>
      The World Bank API receives the City, State and Country of the user from the Teleport API. It then uses this information to retrieve the Country Code of the user and along with the Country Code 
      and The information received from the Teleport API, it makes a new API request to the Open Weather Map API
    </p>
    <br>

    <h5>LCD Screen</h5>
    <p>
      The LCD Screen displays the current station, volume, weather, location and time information to the user.
      It also displays the currently active state slot selected by the user
    </p>
    <br>


    <figure class="imageContainer">
      <img id='radio-io-3' loading="lazy" src="webpages/Radio/radio3.png" onclick="imageClick('radio-io-3', 'imageHoverHorizontal')"></img>
      <figcaption>LCD Screen</figcaption>
    </figure>
    <br>

    <h5>Save States, Control Knobs, Rearranging Components</h5>
    <p>
      The Save States Allows the users to save up to 6 stations at a time. By Selecting one of the 6 numbered buttons 
      they set the numbered save state as active. Then they can either save a new station or load a station that was previously
      saved to that save state.
    </p>
    <p>
      The Power Button turns the lcd screen on and off, while also pausing the currently playing track.
      The Tuner Knob Changes Stations meanwhile the Volume Knob increases/decreases the volume
     </p>
     <p>
      The Control Knobs and LCD can also be rearranged simply by dragging one of the components on top of the other.
      That's all there is to it, simple feature! Best Way to test it out is to check out the Demo!
    </p>
    <br>

    <figure class="imageContainer">
      <img id='radio-io-4' loading="lazy" src="webpages/Radio/radio4.png" onclick="imageClick('radio-io-4', 'imageHoverHorizontal')"></img>
      <figcaption>Save States and Control Knobs</figcaption>
    </figure>
    <br>

    <h5>Mobile Support</h5>
    <p>
      The Radio offers full mobile support! As the Screen Size Gets Smaller, the Radio auto adjusts and takes on new forms.
      The inspiration for the mobile version was the old school walkmans 
    </p>
    <br>
    <div class="rowFlex" style="justify-content: center" >
      <figure >
        <img id='radio-io-6' loading="lazy" src="webpages/Radio/radio6.png"></img>
        <figcaption style="text-align: center">Small and Compact!</figcaption>
      </figure>
    </div>
    <br>

  <section class="credits">
    <h4>Special Thanks to projectmgame for letting me use his asset</h4>
    <h6>"Jet Set Sonic" (<a href="https://skfb.ly/DUBz">https://skfb.ly/DUBz</a>) by projectmgame is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).

    </h6>
  </section>
  
  `;
}

export function lifeDexHTML() {
  return `

    <h2 class="title">Life Dex</h2>
    <h4>Techology Used</h4>
    <ul>
      <li>Languages: Dart, PHP, Python</li>
      <li>Libraries: Flutter, FFmpeg</li>
      <li>Tools: VS Studio Code, Google Cloud, Firebase, AutoML Vision, VM</li>
    </ul>

    <br>
    <h4>Description</h4>
    <p>
      Life dex is a mobile app created using Google's Dart programming language and Flutter Framework. 
      The inspiration for this application was the subreddit r/whatisthisthing and the Pokemon Pokedex(Figure 1 & 2). 
      In otherwords, when a user encounters an unknown object, they take a picture of it using their phones camera and Lifedex is 
      able to recognize the object and return a description of it. 
    </p>
    <br>
    <div class="rowFlex">
      <figure class="imgLeft imageContainerVert">
        <img id='pokedex1' loading="lazy" src="webpages/LifeDex/pokedex1.png" onclick="imageClick('pokedex1', 'imageHoverVertical')"></img>
        <figcaption>Figure 1. Pokedex Example</figcaption>
      </figure>
      <figure class="imgRight imageContainerVert">
        <img id='pokedex2' loading="lazy" src="webpages/LifeDex/pokedex2.png" onclick="imageClick('pokedex2', 'imageHoverVertical')"></img>
        <figcaption>Figure 2. Pokedex Example</figcaption>
      </figure>
    </div>
    <br>
    <h5>Firebase</h5>
    <p>
      Firebase was used to store the users information and authenticate users when logging in. Since Firebase is a NoSQL based database, making 
      quick changes to documents and collections helped with development early on. Firestore Storage was also used to store
      the information for the objects the AI model was able to detect. 
    </p>
    <br>
    <figure class="imageContainer">
      <img id='firebase-pokedex' src="webpages/LifeDex/firebase.png" onclick="imageClick('firebase-pokedex', 'imageHoverHorizontal')"></img>
      <figcaption>LifeDex in Google Cloud</figcaption>
    </figure>
    <figure class="imgRight imageContainerVert">
      <img id='result-login-pokedex' loading="lazy" src="webpages/LifeDex/resultLogin.png" onclick="imageClick('result-login-pokedex', 'imageHoverVertical')"></img>
      <figcaption>Figure 3. Login Screen</figcaption>
    </figure>
    <br>
    <h5>Google Cloud and AutoVision ML</h5>
    <p>
      Life dex is able to recognize objects that its AI Model was trained with using Google's Cloud AutoML Vision. When the user takes a picture, 
      it is sent to a Virtual Machine running an Apache Server on Google Cloud. The VM esentially runs a PHP script that acts like middleware between the App and AI Model.
    </p>
    <p>
      In order to feed the AI Model sufficent images for training, 30 second clips of the desired object to be recognized where taken then spliced into
      various images using FFmpeg. After optaining the images, a python script would handle pushing the images to Google's Cloud Storage and creating a CSV file with the appropriate
      Image Path and respective labels. This CSV file is then fed into AutoVision ML to produce the AI Model. 
    </p>
    <br>
    <figure class="imageContainer">
      <img id="google-cloud-pokedex' loading="lazy" src="webpages/LifeDex/GoogleCloud.png" onclick="imageClick('google-cloud-pokedex', 'imageHoverHorizontal')"></img>
      <figcaption>LifeDex in Google Cloud</figcaption>
    </figure>
    <br>
    <h5>Putting It All Together</h5>
    <p>
      After a user makes an account they are directed to an empty history list. When a user scans an object 
      and it is detected by the App, it redirects them to the objects details page(figure 5). It then stores 
      the scan object into their history page(figure 4), just like in a pokedex.
    </p>
    <br>
    <div class="rowFlex">
      <figure class="imgLeft imageContainerVert">
        <img id='result-list-pokedex' loading="lazy" src="webpages/LifeDex/resultList.png" onclick="imageClick('result-list-pokedex', 'imageHoverVertical')"></img>
        <figcaption> Figure 4. User Scan History</figcaption>
      </figure>
      <figure class="imgRight imageContainerVert">
        <img id='result-info-pokedex' loading="lazy" src="webpages/LifeDex/resultInfo.png" onclick="imageClick('result-info-pokedex', 'imageHoverVertical')"></img>
        <figcaption>Figure 5. Scan Object Information</figcaption>
      </figure>
    </div>
    <br>

  <section class="credits">
    <h4>Special Thanks to Jan Esch for letting me use his asset</h4>
    <h6>"Twinleaf Town" (<a href="https://skfb.ly/6ZOQ6">https://skfb.ly/6ZOQ6</a>) by Jan Esch is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
    </h6>
  </section>
  
  `;
}

export function photoGalleryHTML() {
  return `
    <h2 class="title">Photo Gallery</h2>
    <h4>Techology Used</h4>
      <ul>
        <li>Languages: javascript</li>
        <li>Tools: VS Studio Code</li>
      </ul>
    <br>
    <h4>Description</h4>
    <p>
      With Photo Gallery, users are able to create a custom gallery to express themselves.
      Via the settings users are able to:
    </p>
    <br>

    <div class="row">
      <ul>
        <div class="listLeft">
          <li>Change The Banner Text</li>
          <li>Change Banner Image</li>
          <li>Change Background Image</li>
          <li>Text Color</li>
        </div>
        <div class="listRight">
          <li>Text Color Glow</li>
          <li>Banner Color Theme</li>
          <li>Image Color Theme</li>
          <li>Delete and Add Images</li>
        </div>
      </ul>
    </div>

    <br>
    <div class="video">
      <h4>Photo Gallery Demo</h4>
        <video width="320" height="240" controls>
          <source src="webpages/PhotoGallery/demo.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
      <h5>Note: File Explorer and Color Picker where not picked up by video recording software</h5>
    </div>

  
  `;
}

/*NOTE: GAME DEV*/
export function gyroEngineHTML() {
  return `

    <h2 class="title">Gyro Engine</h2>
    <h4>Techology Used</h4>
      <ul>
          <li>Languages: C++, Lua</li>
          <li>Libraries: SDL2</li>
          <li>Tools: VS Studio Code</li>
      </ul>

    <h4>Description</h4>
    <p>
      The Gyro Engine is a 2D game engine, developed with the intention of learning the inner workings
      of how game engines work. It was created using an Entity Component System (ECS), where each object in the
      game is an entity that is the parent of one or more components.
      The components supported by Gyro Engine are: 
      <div class="row">
      <br>
      <ul>
        <div class="listLeft">
          <li>Audio</li>
          <li>Collider</li>
          <li>Input</li>
          <li>Emitter</li>
        </div>
        <div class="listRight">
          <li>Static and Animated Sprites</li>
          <li>UI</li>
          <li>Tiles</li>
          <li>Transform</li>
        </div>
      </ul>
      </div>
    </p>
    <br>
    <p>In order to streamline creating games with the engine, I integrated Lua as a scripting Language(Figure 1). Lua gives
      the programmer the power of making quick changes without the worry of having to recompile the entire engine.
    </p>
    <br>
    <figure class="imageContainer">
      <img id="gyro-1" loading="lazy" src="webpages/GyroEngine/lua.gif" onclick="imageClick('gyro-1', 'imageHoverHorizontal')">
      <figcaption>Figure 1. Example Lua Code</figcaption>
     </figure>
     <br>

    <p>You can't really call it a game engine without using it to create an actual Game!(Figure 2)
      So In order to test the capabilities of the engine, I created a simple Helicoptor based game.
      The goal of the game is to reach the helipad while avoiding enemy fire.
    </p>
    <br>

    <figure class="imageContainer">
      <img id="gyro-2" loading="lazy" src="webpages/GyroEngine/heli.gif" onclick="imageClick('gyro-2', 'imageHoverHorizontal')">
      <figcaption>Figure 2. Gameplay</figcaption>
    </figure>
    <br>
    <section class="credits">
      <h4>Special Thanks to Yury Misiyuk for letting me use his asset</h4>
      <h6>"Cosmonaut on a rocket" (<a href="https://skfb.ly/o6AyV">https://skfb.ly/o6AyV</a>) by Yury Misiyuk is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
      </h6>
    </section>
  `;
}

export function spaceInvadersHTML() {
  return `
    <h2 class="title">Space Invaders</h2>
    <h4>Techology Used</h4>
      <ul>
          <li>Languages: Python</li>
          <li>Libraries: Pygame</li>
          <li>Tools: VS Studio Code, Pycharm, Piskel</li>
      </ul>
    <br>
    <h4>Description</h4>

    <p>
      A recreation of the classic space invaders using python and the pygame library.
      The player starts with 3 lives and has to traverse as many levels as possible while
      attempting to attain the highest score. 

      <div class="video">
        <video width="320" height="240" controls>
          <source src="webpages/SpaceInvaders/Alien Invasion.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <h5>Gameplay of First Level</h5>
      </div>
    </p>
    <br>

    <section class="credits">
      <h4>Special Thanks to Sirhaian for letting me use his asset</h4>
      <h6>"SGP 25 - Space Invader" (<a href="https://skfb.ly/JyMw">https://skfb.ly/JyMw</a>) by Sirhaian is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
      </h6>
    </section>
  
  `;
}

export function superMarioBrosHTML() {
  return `

    <h2 class="title">Super Mario Bros</h2>
    <h4>Techology Used</h4>
      <ul>
          <li>Languages: C#</li>
          <li>Tools: VS Studio Code, Unity</li>
      </ul>
    <br>
    <h4>Description</h4>
    <p>
      A recreation of the original super mario bros for the Nintendo Entertainment System.
      The goal was to attempt to recreate as many of the gameplay mechanics that were present in the
      original game. This includes:
      <br>
      <div class="row">
        <ul>
          <div class="listLeft">
            <li>Animations</li>
            <li>Powerups</li>
            <li>Gameplay</li>
          </div>
          <div class="listRight">
            <li>Audio System</li>
            <li>Level Design</li>
            <li>Collisions</li>
          </div>
        </ul>
      </div>
    </p>
    <div class="video">
      <video width="320" height="240" controls>
        <source src="webpages/SuperMarioBros/AssetsDemo.mp4" type="video/mp4">
      Your browser does not support the video tag.
      </video>
      <h5>Overview of Assets</h5>
    </div>
    <br>
    <h4>Tile Maps</h4>
    <p>The sprites used to create and design levels were placed into tile maps.
    This vastly improves productivity by enabling the quick design of levels.</p>
    <br>
    <div class="video">
      <video width="320" height="240" controls>
      <source src="webpages/SuperMarioBros/TileMap.mp4" type="video/mp4">
      Your browser does not support the video tag.
      </video>
      <h5>Tile Map in Action</h5>
      <h5>Note: Tilemap window was not picked up by video recording software</h5>
    </div>
    <br>
    <h4>Animations</h4>
    <p>
      Unitys animation system uses a finite state machine. This allows for the quick transition from one animation
      to another when certain conditions are met. For example Mario can only transition into a jump animation
      stage when he is grounded and the jump key is pressed and exits the jump animation when he is grounded.
    </p>
    <br>
    <div class="video">
      <video width="320" height="240" controls>
        <source src="webpages/SuperMarioBros/FiniteStateMachine.mp4" type="video/mp4">
      Your browser does not support the video tag.
      </video>
      <h5>Mario's Animation Finite State Machine</h5>
    </div>
    <br>
    <h4>Scripts</h4>
    <p>
      Scripting is where the real magic happens. It allows for all of the games logic
      to come to life. Mario's jumping ability, the goomba's movement, the games powerups are all
      possible due to scripts. They can get pretty complex however, for example here is the enemy
      koopa's script:
    </p>
    <br>
    <div class="video">
      <video width="320" height="240" controls>
        <source src="webpages/SuperMarioBros/KoopaScript.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <h5>The Koopa's Script</h5>
    </div>
    <br>
    <h4>Prefabs</h4>
    <p>
      The player, enemies and powerups where all made into prefabs.
      By creating prefabs, any components and scripts attatched to it are 
      also attatched to any future instances of the same prefab. This allows for a 
      quick drag and drop approach when adding these objects to the scene. 
    </p>
    <br>
    <div class="video">
      <video width="320" height="240" controls>
        <source src="webpages/SuperMarioBros/Prefabs.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <h5>Prefabs and Gameplay</h5>
    </div>

    <br>

    <section class="credits">
      <h4>Special Thanks to edimartin for letting me use his asset</h4>
      <h6>"Amiibo Mario 8bit classic" (<a href="https://skfb.ly/HDHy">https://skfb.ly/HDHy</a>) by edimartin is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).

      </h6>
    </section>

  `;
}

export function crossyRoadsHTML() {
  return `
    <h2 class="title">Crossy Roads</h2>
    <h4>Techology Used</h4>
      <ul>
          <li>Languages: C++, UE4 Blueprints</li>
          <li>Tools: VS Studio Code, Unreal Engine 4, MagicaVoxel</li>
      </ul>
    <br>
    <h4>Description</h4>

    <p>
      With this project I attempted to recreate Crossy Roads in Unreal Engine 4.
      The purpose of this project was to learn how to use Unreal Engine 4 and as 
      many of it's underlying systems by recreating the popular mobile game Crossy Roads.
    </p>
    <br>
    <h4>MagicaVoxel</h4>
    <p>
      MagicaVoxel is a program that allows you to create voxel based art.
      It is pretty intuitive making it an excellent tool to quickly make art
      assets for projects. It also allows users to export files in .fbx format making it 
      UE4 ready, without having to use any middleware. 
    </p>
    <br>
    <a href="https://ephtracy.github.io/" class="link">Link to Magicavoxel website</a>
    <br>
    <br>
    <div class="rowFlex">

      <figure class="imageContainer">
        <img id="crossy-1" loading="lazy" src="webpages/CrossyRoads/crossy_characters.png"  onclick="imageClick('crossy-1', 'imageHoverHorizontal')">
        <figcaption style="font-size: 12px">Game Assets Created in MagicaVoxel</figcaption>
      </figure>
        <figure class="imageContainer">
        <img id="crossy-2" loading="lazy" src="webpages/CrossyRoads/crossy_characters_2.png"  onclick="imageClick('crossy-2', 'imageHoverHorizontal')">
        <figcaption style="font-size: 12px">Game Assets Created in MagicaVoxel</figcaption>
      </figure>
    </div>
    <br>
    <h4>Gameplay Loop</h4>
    <p>
      The purpose of the game is to keep crossing roads, while increasing your points and collecting coins.
      The player earns points by crossing lanes and avoiding obstacles. Obstacles include moving cars, trains and 
      falling into rivers. The level is proceduraly generated so each game is unique to the player, and the game ends when
      a player dies due to an obstacle
    </p>
    <br>
    <div class="video">
      <video width="320" height="240" controls>
        <source src="webpages/CrossyRoads/crossyroad_vid.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <h5>Crossy Roads Gameplay</h5>
    </div>

  `;
}

/*NOTE: Tools/Extensions*/
export function lofiItHTML() {
  return `
    <h2 class="title">Lofi-It!</h2>
    <h4>Techology Used</h4>
      <ul>
          <li>Languages: python</li>
          <li>Tools: VS Studio Code, Blender</li>
      </ul>
    <br>
    <h4>Description</h4>
    <p>Lofi-it! is a blender script that automates the process of creating and editing Lofi Videos.
    By inputting data into a json file and parsing it via the script, you're able to generate an edited video within seconds of running the script(Figure 1).
    This saves precious time that would be spent positioning and text labels, adjusting font sizes, splicing video clips, etc.. Therefore you're able to 
    produce 10 videos in the time it'd take you to edit 1 by hand!
    Data you're able to input into the json file includes: 
    <div class="row">
      <ul>
        <div class="listLeft">
          <li>Artist Name</li>
          <li>Artist Social Media</li>
          <li>Font Color</li>
          <li>Font Type</li>
        </div>
        <div class="listRight">
          <li>Song Length</li>
          <li>Song Path</li>
          <li>Song title</li>
          <li>Video Clip Path</li>
        </div>
      </ul>
    </div>
    <br>
    <figure class="imageContainer">
      <img id='lofi-it-1' loading="lazy" src="webpages/LofiIt/script.gif" onclick="imageClick('lofi-it-1', 'imageHoverHorizontal')">
      <figcaption>Figure 1. Lofi-It! In Action</figcaption>
    </figure>
    <br>
  
    <div class="video">
      <h4>Sample Result After Rending</h4>
      <video width="320" height="240" controls>
        <source src="webpages/LofiIt/example.mp4" type="video/mp4">
      Your browser does not support the video tag.
      </video>
      <h5>Note: actual video is over 12 minutes long</h5>
    </div>
    <br>

    </p>

  `;
}

export function tuffyBotHTML() {
  return `
    <h2 class="title">Tuffy Bot</h2>
    <h4>Techology Used</h4>
      <ul>
          <li>Languages: javascript</li>
          <li>Libraries: discord.js</li>
          <li>Tools: VS Studio Code, Heroku, Discord</li>
      </ul>
    <br>

    <h4>Description</h4>
    <p>Tuffy bot is a javascript based bot I created for the Cal State Fullerton University Discord Server. 
    It's purpose is to help students find valuable information by just typing simple commands into the server. 
    A list of the commands are as followed:   </p>
    <div class="row">
      <ul>
        <div class="listLeft">
          <li>!help</li>
          <li>!important-dates</li>
        
        </div>
        <div class="listRight">
        <li>!professor-info</li>
        <li>!assign-role</li>
        </div>
      </ul>
    </div>
    <br>

    <h4>The !help command</h4>
    <p>
      The help command gives the students a list of all the commands availabe,
      a discription of what the commands do, and a usage example
    </p>
    <br>
    <div class="rowFlex">
      <figure class="imageContainer">
        <img id="tuffy-1" loading="lazy" src="webpages/Tuffy/helpCommand.png" onclick="imageClick('tuffy-1', 'imageHoverHorizontal')">
        <figcaption>Help Command in Action</figcaption>
      </figure>
      <figure class="imageContainer">
        <img id="tuffy-2" loading="lazy" src="webpages/Tuffy/helpCommand2.png" onclick="imageClick('tuffy-2', 'imageHoverHorizontal')">
        <figcaption>Using help to get additonal information on a specific command</figcaption>
      </figure>
    </div>
    <br>
  
    <h4>The !important-dates command</h4>
    <p>
      With this command, students can obtain important dates for the entirety of the semester. This includes
      holidays, the first week of the semester, finals week, the lenght of winter break and so on.
    </p>
    <br>

    <figure class="imageContainer">
      <img id='tuffy-3' loading="lazy" src="webpages/Tuffy/importantDates.png" onclick="imageClick('tuffy-3', 'imageHoverHorizontal')">
      <figcaption>!Important Dates in Action</figcaption>
    </figure>
    <br>
    
    <h4>The !professor info command</h4>
    <p>
      By using this command a student is able to pull quick information on their proffessors without the hassle of 
      trying to find it online. The information a student can find on their professor includes, their office hours, 
      phone number and email. 
    </p>
    <br>

    <figure class="imageContainer">
      <img id='tuffy-4' loading="lazy" src="webpages/Tuffy/professorInfo.png" onclick="imageClick('tuffy-4', 'imageHoverHorizontal')">
      <figcaption>Note: Email and phone number partialy covered for privacy</figcaption>
    </figure>
    <br>

    <h4>The !assign role command</h4>
    <p>With the assign role command, students are able to assign their current education level status. 
    By letting everyone else in the server know where they stand, it makes it easier to network with other students
    The roles a student can assign to themselves are: 
    </p>
    <br>

    <div class="row">
      <ul>
        <div class="listLeft">
          <li>Freshman</li>
          <li>Sophomore</li>
          <li>Junior</li>
        
        </div>
        <div class="listRight">
          <li>Senior</li>
          <li>Graduate</li>
          <li>Grad Student</li>
        </div>
      </ul>
    </div>
    <br>

    <figure class="imageContainer">
      <img id='tuffy-5' loading="lazy" src="webpages/Tuffy/assignRoles.png" onclick="imageClick('tuffy-5', 'imageHoverHorizontal')">
      <figcaption>!assing-role in action</figcaption>
    </figure>
    <br>

    <section class="credits">
      <h4>Special Thanks to Hadrien59 for letting me use his asset</h4>
      <h6>"Robot Playground" (<a href="https://skfb.ly/6QXFq">https://skfb.ly/6QXFq</a>) by Hadrien59 is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).


      </h6>
    </section>

  `;
}

/*NOTE: Algorithms*/
export function farmzoidHTML() {
  return `
    <h2 class="title">Capstone Project: Farmzoid</h2>
    <h4>Techology Used</h4>
      <ul>
          <li>Languages: javascript, lisp</li>
          <li>Tools: VS Studio Code</li>
      </ul>
    <br>

    <h4>Description</h4>
    <p>
      The goal of Farmzoid was to develop a group of farmzoids to perform, planting, growing, and harvesting on a farm.
      Nature also plays a role in how the farmzoids harvest crops, and both Nature and the Farmzoids are implemented using 
      a rule-bases system. The farm is located on a large grid where the bot must traverse through it while avoiding certain obstacles. 
      The obstacles include: 
      <div class="row">
        <ul>
          <div class="listLeft">
            <li>Other Farmzoids</li>
            <li>Soil Patches</li>
            <li>The River</li>
          </div>
          <div class="listRight">
            <li>The Barn</li>
            <li>The Mine</li>
          </div>
        </ul>
      </div>
    </p>
    <br>

    <h4>The Barn</h4>
    <p>
      The barn(Figure 1) contains all the resources, equipment and harvest storage needed. The farmzoids must know when is the 
      ideal time to store the harvest, and retrieve new equipment. 
    </p>
    <br>

    <figure class="imageNonExpand">
      <img id="farmzoid-1" loading="lazy" src="webpages/Farmzoid/barn.png" onclick="imageClick('farmzoid-1', 'imageHoverHorizontal')">
      <figcaption>Figure 1. The Barn</figcaption>
    </figure>
    <br>

    <h4>Nature</h4>
    <p>
      Nature updates the details of each plant: its look, color, water reserve, age and current fruiting
      Nature also enages in some adverse effects: clouds, rain, wind temperature, and blight.
      A day of clouds delays plant growth that day. Clouds have a 10% each day, and rain happens with a 50% 
      probability each day. Rain adds 1 unit of water to each plants reserves. Blight has a 2% chance of occuring and
      stalls the plants growth until it is washed with soapy water. Blight Wind has 30% chance of occuring and a cold snap has
      a 50% of occuring after a rainy day and lasts 2 days. If plants are not protected, cold issues occur. A farmzoid can place 3 
      smudge pots for protection.
    </p>
    <br>
  
    <h4>Plants</h4>
    <p>
      The plant must maintain a water level from 1-3 or it starts to wither. It takes 15 days for a healthy plant to be ready for harvest
      A planted seed grows when it has water and is fertilized. The plants stages are:
      
      <br>
      <br>
      <div class="row">
        <ol>
          <div class="listLeft">
            <li>Green-Colord Stalk</li>
            <li>Tall Bush</li>
            <li>Tree</li>
            <li>Flowering</li>
            <li>Green: Ripe Fruit</li>
          </div>
          <div class="listRight">
            <li>Red: Can Be Harvested</li>
            <li>Yellow Withering: Drying Out</li>
            <li>Brown Withering: Dying</li>
            <li>Black Withering: Dead</li>
          </div>
        </ol>
      </div>
      <br>

      <figure class="imageContainer">
        <img id="farmzoid-2" loading="lazy" src="webpages/Farmzoid/TreePhases.png" onclick="imageClick('farmzoid-2', 'imageHoverHorizontal')">
        <figcaption>Crop Life Cycle</figcaption>
      </figure>

    </p>
    <br>

  <h4>Farmzoids</h4>
    <p>
      A farmzoid can only make 50 moves per day and knows how to move from one location to another while avoiding collision.
      This was achieved by using Axis-Aligned Bounding Box Collision. When it comes to obtaining barn resources, water, planting seeds
      the bot determines the closest path to obtaining the needed resource by using An A* Algorithm, in this case the Manhattan Distance(Figure 2). 
      <br>
      <br>
      <figure class="imageContainer">
      <img id="farmzoid-3" loading="lazy" src="webpages/Farmzoid/manhattan.png" onclick="imageClick('farmzoid-3', 'imageHoverHorizontal')">
      <figcaption>Figure 2. Manhattan Distance</figcaption>
    </figure>
    </p>


    <br>
    <p>
      Originally the production rules set was overly complex but I found that by optimizing the farmzoids decision tree(Figure 3)
      that I could reduce the production rules to just two! The Production rules being:
      <ol>
        <li>Go To Barn</li>
        <li>Work Soil</li>
      </ol>

      <figure class="imageContainer">
        <img id="farmzoid-4" loading="lazy" src="webpages/Farmzoid/decisiontree.png" onclick="imageClick('farmzoid-4', 'imageHoverHorizontal')">
        <figcaption>Figure 3. Farmzoids Decision Tree</figcaption>
      </figure>

    </p>
    <br>

    <h4>Result</h4>
    <p>On Average the farmzoids harvested 340 fruits, with the lowest outlier observed being 320 and highest being 360</p>
    <br>
    <div class="video">
    <h5>Farmzoids Maintaing Plants</h5>
      <video width="320" height="240" controls>
        <source src="webpages/Farmzoid/farmzoidFarming.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
    <br>
    <div class="video">
      <h5>Farmzoids Harvesting</h5>
        <video width="320" height="240" controls>
          <source src="webpages/Farmzoid/farmzoidFarming2.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
    </div>
    <br>
    <div class="video">
      <h5>Farm Full View</h5>
        <video width="320" height="240" controls>
          <source src="webpages/Farmzoid/farmzoidFarming3.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
    </div>
    <br>

  `;
}
